<template>
  <div>
    <!-- <h4>Suara Caleg</h4> -->
    <b-row>
      <b-col cols="6">
        <p class="mb-0"><strong>Nama</strong></p>
      </b-col>
      <b-col cols="6">
        <p class="mb-0"><strong>Perolehan Suara</strong></p>
      </b-col>
    </b-row>
    <b-row v-for="(calegDetail, index) in caleg" :key="calegDetail.id" class="my-3 align-items-center">
      <b-col cols="6">{{ calegDetail.number + '. ' + calegDetail.name }}</b-col>
      <b-col cols="6">
        <b-input
          style="width: 50%;"
          type="number"
          @click="removeZero"
          v-model="calegDetail.vote_count"
          placeholder="Input suara"
          label-placement="stacked"
          :autofocus="index === 0"
          min="0"
        ></b-input>
      </b-col>
    </b-row>

    <!-- <ion-list>
      <ion-item
        v-for="calegDetail in caleg"
        :key="calegDetail.id"
        class="ion-no-padding"
      >
        <ion-input
          class="input-suara"
          type="number"
          v-model="calegDetail.vote_count"
          placeholder="Input suara"
          label-placement="stacked"
          :label="calegDetail.number + '. ' + calegDetail.name"
        ></ion-input>
      </ion-item>
    </ion-list> -->
  </div>
</template>

<script>
export default {
  props: {
    caleg: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    removeZero (evt) {
      if (!evt.target.value || parseInt(evt.target.value) < 1) {
        evt.target.value = null
      }
    },
  }
};
</script>