<template>
  <div v-show="!isLoading">
    <b-progress v-show="isLoading" :value="100" :max="100" animated></b-progress>
    <b-card>
      <template v-if="mode === 'input'" #header>
        <div class="d-flex justify-content-between px-3">
          <!-- <PartyDetail :party="selected.party" v-if="mode === 'input'" /> -->
          <router-link
            :to="'/kelurahan-suara'"
            class="h3"
          >
            <font-awesome-icon icon="arrow-left" />
          </router-link>
        </div>
      </template>

      <b-card-text v-if="mode === 'upload-formulir'" class="px-5 my-3">
        <p>Upload Formulir Model D (hanya menerima file PDF)</p>
        <b-form-file
          style="margin-top: 10px"
          @change="onFileChange"
          accept="application/pdf"
          placeholder="Upload formulir model D"
          label-placement="stacked"
        ></b-form-file>
        <b-progress v-show="isProgressing" :value="100" :max="100" animated></b-progress>
      </b-card-text>

      <b-card-text
        :style="mode === 'input' ? 'max-height: 230px; overflow-y: auto' : ''"
        class="px-5 my-3"
      >
        <!-- <b-row class="mb-2 align-items-center"  v-if="mode === 'input'">
          <b-col cols="6">Suara Partai</b-col>
          <b-col cols="6">
            <b-input
              style="width: 50%;"
              type="number"
              v-model="selected.party_vote_count"
              @click="removeZero"
              placeholder="Input suara"
              label-placement="stacked"
              min="0"
            ></b-input>
          </b-col>
        </b-row> -->
        <CalegList :caleg="list.caleg" v-if="mode === 'input'" />

        <div v-if="mode === 'confirm'">
          <b-row class="my-3">
            <b-col cols="4">
              <label>Jumlah suara sah</label>
            </b-col>
            <b-col cols="8">
              <b-input
                readonly
                type="number"
                placeholder="Jumlah suara sah"
                :value="list.caleg.length > 0 ? totalSuara : 0"
              />
            </b-col>
          </b-row>

          <b-row class="my-3">
            <b-col cols="4">
              <label>Total surat suara</label>
            </b-col>
            <b-col cols="8">
              <b-input
                autofocus
                type="number"
                v-model="form.summary.total_surat_suara"
                placeholder="Input total surat suara"
              />
            </b-col>
          </b-row>

          <b-row class="my-3">
            <b-col cols="4">
              <label>Total surat suara tidak sah</label>
            </b-col>
            <b-col cols="8">
              <b-input
                type="number"
                v-model="form.summary.total_suara_tidak_sah"
                placeholder="Input total surat suara tidak sah"
              />
            </b-col>
          </b-row>

          <b-row class="my-3">
            <b-col cols="4">
              <label>Jumlah suara tidak terpakai(Terisi Automatis)</label>
            </b-col>
            <b-col cols="8">
              <b-input
                readonly
                type="number"
                placeholder="Jumlah suara tidak terpakai"
                :value="
                  parseInt(form.summary.total_surat_suara) -
                  totalSuara -
                  parseInt(form.summary.total_suara_tidak_sah)
                "
              />
            </b-col>
          </b-row>
        </div>

        <b-progress v-show="isProgressing" :value="100" :max="100" animated></b-progress>
      </b-card-text>

      <template #footer v-if="mode === 'input'">
        <b-row class="px-5">
          <b-col cols="6">
            <h5>Total Suara</h5>
          </b-col>
          <b-col cols="6">
            <b-input
              v-model="totalSuaraPartai"
              placeholder="0"
              type="number"
              style="width: 50%"
              disabled
            ></b-input>
          </b-col>
        </b-row>
      </template>
    </b-card>

    <b-card v-if="mode === 'completed'">
      <div style="margin-top: 10px">
        <h3>
          Data suara TPS ini sudah selesai diinput, silahkan tutup halaman ini.
        </h3>
      </div>
    </b-card>

    <div
      :class="
        `d-flex align-items-center my-3` +
        (lastPartyNumber > 1
          ? ' justify-content-between'
          : ' justify-content-end')
      "
    >
      <b-button
        v-if="lastPartyNumber > 1"
        @click.prevent="doPrev"
        variant="warning"
        class="text-white mr-3"
        >Kembali</b-button
      >
      <b-button
        @click.prevent="doNext"
        v-if="lastPartyNumber <= list.party.length"
        variant="primary"
        >Selanjutnya</b-button
      >
      <b-button
        @click="presentAlertUpload"
        v-else
        :disabled="!list.party.length"
        variant="primary"
        >Simpan</b-button
      >
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import { serializeErrorMessage } from "@/_helpers";
import PartyDetail from "./Forms/PartyDetail.vue";
import CalegList from "./Forms/CalegList.vue";

export default {
  components: {
    PartyDetail,
    CalegList,
  },
  data() {
    return {
      isLoading: true,
      isProgressing: false,
      mounted: false,
      unmounted: false,
      mode: "input",
      idKelurahan: this.$route.params.idKelurahan,
      level: parseInt(this.$route.params.level),
      tpsId: this.$route.params.tpsId,
      lastPartyNumber: 1,
      regionId: "",
      levelName: "",
      loadingController: null,
      localDataSuara: null,
      form: {
        formulir_file: null,
        summary: {
          total_surat_suara: null,
          total_suara_tidak_sah: null,
        },
      },
      selected: {
        party: null,
        party_vote_count: 0
      },
      list: {
        party: [],
        caleg: [],
      },
    };
  },
  async unmounted() {
    this.unmounted = true;
    this.whenUnmounted();
  },
  mounted() {
    this.mounted = true;
    this.whenMounted();
  },
  computed: {
    totalSuaraPartai() {
      let total = 0;
      this.list.caleg.forEach((caleg) => {
        total += parseInt(caleg.vote_count ? caleg.vote_count : 0);
      });
      total += parseInt(this.selected.party_vote_count ? this.selected.party_vote_count : 0)
      return total;
    },
    totalSuara() {
      let total = 0;
      if (this.localDataSuara && this.localDataSuara.votes_data.length) {
        this.localDataSuara.votes_data.forEach((localDataSuara) => {
          localDataSuara.votes_data.forEach((partyData) => {
            total += parseInt(partyData.vote_count);
          });
          total += parseInt(localDataSuara.party_vote_count ? localDataSuara.party_vote_count : 0)
        });
      }
      return total;
    },
  },
  watch: {},
  methods: {
    removeZero (evt) {
      if (!evt.target.value || parseInt(evt.target.value) < 1) {
        evt.target.value = null
      }
    },
    onFileChange($event) {
      if ($event.target.files && $event.target.files.length) {
        this.form.formulir_file = $event.target.files[0];
      } else {
        this.form.formulir_file = null;
      }
    },
    async whenMounted() {
      this.checkLevel();
      await this.showLoading("Mengecek data tersimpan");
      setTimeout(async () => {
        try {
          const lastVerificationWork = await this.$localDb
            .lastVerificationWork()
            .get();
          if (
            lastVerificationWork &&
            (lastVerificationWork.idKelurahan !== this.idKelurahan ||
              lastVerificationWork.regionId !== this.regionId ||
              lastVerificationWork.tps !== this.tpsId)
          ) {
            await this.$localDb.dataSuara().reset();
          }
          if (!(await this.$localDb.dataSuara().get())) {
            const resp = await this.$http.get(
              process.env.VUE_APP_API_URL +
                "input-suara/verifikator/worksheet/" +
                this.idKelurahan +
                "/" +
                this.regionId +
                "/" +
                this.tpsId
            );

            let responseJson = await fetch(
              // 'http://localhost:3000/data-suara.json'
              resp.data.data.petugas_json_file_path
            );
            responseJson = await responseJson.json();
            this.localDataSuara = await this.$localDb
              .dataSuara()
              .saveAll(responseJson.data_suara);

            const summary = await this.$localDb
              .dataSummarySuara()
              .save(responseJson.summary);
            if (summary) {
              this.form.summary.total_surat_suara = summary.total_surat_suara;
              this.form.summary.total_suara_tidak_sah =
                summary.total_suara_tidak_sah;
            }
            await this.$localDb.lastVerificationWork().save({
              idKelurahan: this.idKelurahan,
              regionId: this.regionId,
              tps: this.tpsId,
            });
          } else {
            this.localDataSuara = await this.$localDb.dataSuara().get();
            const summary = await this.$localDb.dataSummarySuara().get();
            if (summary) {
              this.form.summary.total_surat_suara = summary.total_surat_suara;
              this.form.summary.total_suara_tidak_sah =
                summary.total_suara_tidak_sah;
            }
          }
          this.initData();
        } catch (error) {
          await iziToast.error({
            title: "Error",
            theme: "dark",
            message: serializeErrorMessage(error),
          });
        }
      }, 800);
    },
    whenUnmounted() {
      this.lastPartyNumber = 1;
      this.mode = "input";
      if (this.selected.party && this.list.caleg.length > 0) {
        this.$localDb
          .dataSuara()
          .saveByParty(this.selected.party.id, this.list.caleg, this.selected.party_vote_count);
      }
    },
    checkLevel() {
      if (this.level === 1) {
        this.levelName = "DPR";
        this.regionId = process.env.VUE_APP_DPR_ID;
      } else if (this.level === 2) {
        this.levelName = "DPRD TK I";
        this.regionId = process.env.VUE_APP_DPRD_ID;
      } else {
        this.levelName = "DPRD TK II";
        this.regionId = process.env.VUE_APP_DPRD_II_ID;
      }
    },
    async initData() {
      await this.showLoading();
      await this.getParty();
      await this.getCaleg();
      this.isLoading = false;
    },
    selectParty() {
      const selectedParty = this.list.party.filter((party) => {
        return party.sort === this.lastPartyNumber;
      });
      this.selected.party = selectedParty[0];
    },
    async getParty() {
      try {
        this.list.party = await this.$localDb.dataPartai().init();
        this.selectParty();
      } catch (error) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: serializeErrorMessage(error),
        });
      }
      Promise.resolve("success");
    },
    async getCaleg() {
      try {
        const savedData = this.localDataSuara.votes_data.find((localData) => {
          return localData.party_id === this.selected.party.id;
        });
        if (savedData) {
          this.selected.party_vote_count = savedData.party_vote_count
          this.list.caleg = savedData.votes_data;
        } else {
          let resp = await this.$http.get(
            process.env.VUE_APP_API_URL +
              "input-suara/daftar-caleg/" +
              this.regionId +
              "/" +
              this.selected.party.id
          );
          resp = resp.data.data;
          resp.sort((a, b) => {
            return a.number - b.number;
          });
          /* const savedData = this.localDataSuara.votes_data.filter(localData => {
              return localData.party_id === this.selected.party.id
            }) */
          const result = resp.map((caleg) => {
            // let vote_count = caleg.vote_count > 0 ? caleg.vote_count : null;
            let vote_count = null;
            /* if (savedData.length > 0) {
                let savedDataCaleg = savedData.votes_data.filter(localData => {
                  return localData.id === caleg.id
                })
                if (savedDataCaleg.length > 0) {
                  vote_count = savedDataCaleg[0].vote_count
                }
              } */
            return {
              ...caleg,
              vote_count: vote_count,
            };
          });
          this.list.caleg = result;
          this.selected.party_vote_count = 0
        }
      } catch (error) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: serializeErrorMessage(error),
        });
      }
      Promise.resolve("success");
    },
    doValidation() {
      const emptyVoteCount = this.list.caleg.filter((caleg) => {
        return (
          !caleg.vote_count ||
          (caleg.vote_count && caleg.vote_count.trim() === "")
        );
      });
      return emptyVoteCount;
    },
    async doNext() {
      this.isLoading = true;
      this.list.caleg.forEach((caleg) => {
        if (caleg.vote_count === null || caleg.vote_count === "") {
          caleg.vote_count = 0;
        }
      });
      await this.showLoading("Menyimpan Suara...");
      this.localDataSuara = await this.$localDb
        .dataSuara()
        .saveByParty(this.selected.party.id, this.list.caleg, this.selected.party_vote_count);
      this.lastPartyNumber++;
      if (this.lastPartyNumber > this.list.party.length) {
        this.mode = "confirm";
      } else {
        this.selectParty();
        await this.getCaleg();
      }
      this.isLoading = false;
    },
    async doPrev() {
      await this.showLoading();
      this.mode = "input";
      this.lastPartyNumber--;
      this.selectParty();
      await this.getCaleg();
    },
    async presentAlertUpload() {
      if (
        !this.form.summary.total_surat_suara ||
        this.form.summary.total_surat_suara.trim === ""
      ) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: "Lengkapi total surat suara!",
        });
      } else if (
        parseInt(this.form.summary.total_surat_suara) <
        parseInt(this.totalSuara)
      ) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message:
            "Total surat suara tidak boleh lebih kecil dari " +
            this.totalSuara +
            " !",
        });
      } else if (
        !this.form.summary.total_suara_tidak_sah ||
        this.form.summary.total_suara_tidak_sah.trim === ""
      ) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: "Lengkapi total surat suara tidak sah!",
        });
      } else if (
        parseInt(this.form.summary.total_suara_tidak_sah) >
        parseInt(this.form.summary.total_surat_suara) -
          parseInt(this.totalSuara)
      ) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message:
            "Total surat suara tidak boleh lebih besar dari " +
            (parseInt(this.form.summary.total_surat_suara) -
              parseInt(this.totalSuara)) +
            " !",
        });
      } else if (this.mode === "upload-formulir" && !this.form.formulir_file) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: "Formulir tidak boleh kosong!",
        });
      } else {
        await this.$swal
          .fire({
            title: "Anda Yakin?",
            text: "Pastikan Data Sudah Benar!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yakin",
            cancelButtonText: "Tidak",
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (this.mode === "upload-formulir") {
                this.doUploadFormulir();
              } else {
                this.doUploadSuara();
              }
            }
          });
      }
    },
    async doUploadSuara() {
      this.isProgressing = true;
      await this.showLoading("Menyimpan Data...");
      try {
        const dataSuara = await this.$localDb.dataSuara().get();
        await this.$http.post(
          process.env.VUE_APP_API_URL +
            "input-suara/verifikator/worksheet/" +
            this.idKelurahan +
            "/" +
            this.regionId +
            "/" +
            this.tpsId,
          {
            form: {
              summary: {
                total_suara_sah: parseInt(this.totalSuara),
                total_surat_suara: parseInt(
                  this.form.summary.total_surat_suara
                ),
                total_suara_tidak_sah: parseInt(
                  this.form.summary.total_suara_tidak_sah
                ),
                total_suara_tidak_terpakai: parseInt(
                  parseInt(this.form.summary.total_surat_suara) -
                    this.totalSuara -
                    parseInt(this.form.summary.total_suara_tidak_sah)
                ),
              },
              data_suara: dataSuara,
            },
          }
        );
        this.mode = "upload-formulir";
        await iziToast.success({
          title: "Success",
          theme: "dark",
          message: "Data suara berhasil disimpan",
        });
        this.isProgressing = false;
      } catch (error) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: serializeErrorMessage(error),
        });
        this.isProgressing = false;
      }
    },
    async doUploadFormulir() {
      this.isProgressing = true;
      await this.showLoading("Mengunggah Formulir...");
      try {
        const formData = new FormData();
        formData.append("formulir", this.form.formulir_file);
        await this.$http.post(
          process.env.VUE_APP_API_URL +
            "input-suara/verifikator/formulir-model-d/" +
            this.idKelurahan +
            "/" +
            this.regionId +
            "/" +
            this.tpsId,
          formData
        );

        await this.$localDb.dataSuara().reset();
        await this.$localDb.dataSummarySuara().reset();
        await this.$localDb.lastVerificationWork().reset();
        this.list.caleg = [];
        this.selected.party = null;
        this.lastPartyNumber = 1;
        this.mode = "completed";
        alert("Data Formulir Berhasil Diunggah");
        if (window.opener && window.opener.location) {
          window.opener.location.reload();
        }
        this.isProgressing = false;
        window.close();
      } catch (error) {
        this.isProgressing = false;
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: serializeErrorMessage(error),
        });
      }
    },
    async showLoading(text = "Memuat Data...") {
      await this.$swal.fire({
        title: "Mohon Tunggu",
        text: text,
        timer: 500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return Promise.resolve("success");
    },
    async openAlert() {
      await this.$swal
        .fire({
          title: "Perhatian!",
          text: "Pastikan anda mengisi data dengan benar dan teliti.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Mulai",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.value) {
            this.$router.push(
              `/input-suara/calon/${this.$route.params.level}/form`
            );
          }
        });
    },
  },
};
</script>