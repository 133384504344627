<template>
  <div v-if="party" class="d-flex align-items-center font-weight-bold">
    <h2 class="mx-3">{{ party.sort }}</h2>
    <img
      height="50"
      :src="party.logo['']"
      alt=""
    />
    <h2 class="mx-3">{{ party.name }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    party: {
      type: Object,
      default() {
        return {
          id: null,
          logo: null,
          name: null,
          sort: null,
        };
      },
    },
  },
};
</script>